import { createSelector } from "@reduxjs/toolkit";

import {
	getFacilityWithUnits,
	getFacilityCardProps
} from "store/selectors/entities/facility";
import formatPageTypeTitle from "utils/search/formatPageTypeTitle";
import buildSisterPages from "utils/search/buildSisterPages";

// Search Page Meta

export const selectSearchPageMeta = (state) => ({
	cityUrl: state.search.cityUrl,
	meta: {...state.search.meta, canonical: state.app.baseUrl + state.search.meta.canonical},
	pageText: state.search.pageText,
	nearbyCities: state.search.nearbyCities,
	location: state.search.location,
	pageType: state.search.pageType,
	headline:
		state.search.headline || formatPageTypeTitle(state.search.subPageType),
	stateUrl: state.search.stateUrl,
	staticMapUrl:state.search.staticMapUrl
});

export const selectSearchResultsPagination = (state) => ({
	currentPage: state.search.page,
	hasMorePages: state.search.hasMorePages,
	totalListings: state.search.totalListings
});

export const selectSisterPages = (state) => ({
	amenityPages: buildSisterPages(
		state.search.amenityPages,
		state.search.meta.canonicalUri
	)
});

export const selectFacilityCards = createSelector(
	({ facilities }) => facilities.byId,
	({ units }) => units.byId,
	(facilitiesById, unitsById) =>
		Object.keys(facilitiesById)
			.map((i) => getFacilityWithUnits(i, facilitiesById, unitsById))
			.map((f) => getFacilityCardProps(f))
			.filter((f) => f)
);

export const searchPageSelector = createSelector(
	selectSearchPageMeta,
	selectSearchResultsPagination,
	selectSisterPages,
	selectFacilityCards,
	(searchPageMeta, searchResultsPagination, sisterPages, facilityCards) => ({
		...searchPageMeta,
		...searchResultsPagination,
		...sisterPages,
		facilityCards
	})
);

export default searchPageSelector;
