import React from "react";
import PropTypes from "prop-types";
import { Image } from "@sparefoot/react-commons";
import { H1, Text } from "components/core/Typography";
import { CircleArrow } from "components/core/Icons";
import img from "assets/images/clp-header-image.svg";

import SearchBar from "components/search/SearchBar/SearchBar";
import "./CLPHero.scss";

function CLPHero({ heading, description, bottomText }) {
	const linkButtonsData = [
		"Compare nearby storage facilities",
		"Find exclusive deals and pricing",
		"Reserve storage online for free"
	];
	return (
		<div className="clp-header">
			<div className="searchbar-container">
				<H1
					className="heading"
					color="darkBlue"
					weight="bold"
				>
					{heading}
				</H1>
				<Text
					className="desc-text"
					size="large"
					color="gray"
				>
					{description}
				</Text>
				<SearchBar
					buttonText="Find Storage"
					id="hero-search-bar"
					label="Search by city, state, or zip"
					theme="darkBlue"
					segmentCategory="Hero-SearchBar"
					className="searchbar"
				/>

				{bottomText ? (
					<>
						<Text
							size="tiny"
							weight="bold"
							className="text"
							color="gray"
						>
							{bottomText.start}
							<Text color="lightBlue"> {bottomText.middle}</Text>
							{bottomText.end}
						</Text>
					</>
				) : (
					<Text
						className="link-buttons"
						size="small"
						color="gray"
					>
						{linkButtonsData.map((data) => (
							<Text>
								<CircleArrow
									fill="gray"
									size="xlarge"
								/>
								{data}
							</Text>
						))}
					</Text>
				)}
			</div>
			<Image
				src={img}
				alt="clp-header-img"
			/>
		</div>
	);
}

CLPHero.propTypes = {
	heading: PropTypes.string,
	description: PropTypes.string,
	bottomText: PropTypes.bool
};

export default CLPHero;
